import React, { useState, useRef } from "react";
import PopUpDelete from "../PopUpDelete";

const ListSort = ({ tableHead, tableData, category, authenticityToken, skipOptions, customPath }) => {
  const {fields, content} = tableData;
  
  const [tableOrder, setTableOrder] = useState(content);
  const [showSave, setShowSave] = useState(false);

  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const handleDrag = () => {
    const newOrder = [...tableOrder];

		//remove and save the dragged item content
		const draggedItemContent = newOrder.splice(dragItem.current, 1)[0];

		//switch positions
		newOrder.splice(dragOverItem.current, 0, draggedItemContent);

		//reset position refs
    cleanDragReferences();

		//update order
    setTableOrder(newOrder);

    // show save button
    setShowSave(true);
  };

  const handleSave = async () => {
    const params = new FormData();
    params.append('order_ids', JSON.stringify(tableOrder.map(i => i.id)));
    params.append('category', category);
    params.append('authenticity_token', authenticityToken);

    const postPath = customPath ?? '/admin/save_sorting'
    
    await fetch(postPath, {
      method: 'POST',
      body: params,
    })
    
    setShowSave(false);
  };

  const cleanDragReferences = () => {dragItem.current = null; dragOverItem.current = null};

  return (
    <div className="p-4 sort-table">
      <table className="table-general">
        <thead>
          <tr>
            <th></th>
            {tableHead.map(title => (
              <th key={`table-head-${title}`}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableOrder.map((row, index) => (
            <tr
              key={`table-row-${index}`}
              draggable
              onDragStart={() => dragItem.current = index}
              onDragEnter={() => dragOverItem.current = index}
              onDragEnd={handleDrag}
            >
              <td>
                {index + 1}.
              </td>
              {fields.map(field => (
                <td key={`table-data-${field}`} dangerouslySetInnerHTML={{__html: row[field]}} />
              ))}
              {!skipOptions && (<td className="options">
                <a className="modify-icon" href={row.actions.edit}></a>
                <PopUpDelete 
                  button={'<a class="delete-icon"></a>'}
                  url={row.actions.delete}
                  text={`
                    <p class=\"fs-5\">
                      Estas a punto de ELIMINAR este registro
                      <strong class=\"text-danger d-block my-4\">${row[fields[0]]}</strong>
                      Por favor confirmar para continuar
                    </p>`
                  }
                />
              </td>)}
            </tr>
          ))}
        </tbody>
      </table>
      { showSave && (
        <button
          className="btn btn-publish order-btn"
          onClick={handleSave}
          type="button"
        >
          Guardar Orden
        </button>
      )}
    </div>
  )
};

export default ListSort;
