import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { DirectUploadProvider } from "react-activestorage-provider";
import VideoPlayer from "./VideoPlayer";

const loadingIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    style={{ margin: "auto", display: "block" }}
    width="200px"
    height="200px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <path
      d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50"
      fill="#fd495c"
      stroke="none"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        dur="1s"
        repeatCount="indefinite"
        keyTimes="0;1"
        values="0 50 51;360 50 51"
      ></animateTransform>
    </path>
  </svg>
);

const uploadIcon = (
  <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.667969 7.5H2.33464V11.25H15.668V7.5H17.3346V11.25C17.3346 12.0825 16.593 12.75 15.668 12.75H2.33464C1.41797 12.75 0.667969 12.0825 0.667969 11.25V7.5ZM9.0013 0L4.38464 4.095L5.56797 5.16L8.16797 2.8125V9.75H9.83463V2.8125L12.443 5.16L13.6263 4.0875L9.0013 0Z"
      fill="#575757"
    />
  </svg>
);

const videoIcon = (
  <svg
    width="47"
    height="32"
    viewBox="0 0 47 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.25 5.66667V26.3333H5.41667V5.66667H31.25ZM33.8333 0.5H2.83333C1.4125 0.5 0.25 1.6625 0.25 3.08333V28.9167C0.25 30.3375 1.4125 31.5 2.83333 31.5H33.8333C35.2542 31.5 36.4167 30.3375 36.4167 28.9167V19.875L46.75 30.2083V1.79167L36.4167 12.125V3.08333C36.4167 1.6625 35.2542 0.5 33.8333 0.5ZM26.0833 18.5833H20.9167V23.75H15.75V18.5833H10.5833V13.4167H15.75V8.25H20.9167V13.4167H26.0833V18.5833Z"
      fill="#CFC1AF"
    />
  </svg>
);

const btnIcon = (
  <svg
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 13V7H0.5L7.5 0L14.5 7H10.5V13H4.5ZM0.5 17V15H14.5V17H0.5Z"
      fill="#907656"
    />
  </svg>
);

const deleteIcon = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.9987 0.333008C10.6854 0.333008 13.6654 3.31301 13.6654 6.99967C13.6654 10.6863 10.6854 13.6663 6.9987 13.6663C3.31203 13.6663 0.332031 10.6863 0.332031 6.99967C0.332031 3.31301 3.31203 0.333008 6.9987 0.333008ZM9.39203 3.66634L6.9987 6.05967L4.60536 3.66634L3.66536 4.60634L6.0587 6.99967L3.66536 9.39301L4.60536 10.333L6.9987 7.93967L9.39203 10.333L10.332 9.39301L7.9387 6.99967L10.332 4.60634L9.39203 3.66634Z"
      fill="#E31519"
    />
  </svg>
);

const poster = (uid) =>
  uid
    ? `https://customer-agqk7n0hz61pv4iy.cloudflarestream.com/${uid}/thumbnails/thumbnail.jpg?time=1s`
    : "";
const videoUrl = (uid) =>
  uid
    ? `https://customer-agqk7n0hz61pv4iy.cloudflarestream.com/${uid}/manifest/video.m3u8?protocol=llhls`
    : "";

const DirectVideoUpload = ({ enoughStorage, name, videoData, addClass, onEnd }) => {
  const [videoInput, setVideoInput] = useState(videoData.signedId ?? "");
  const [finished, setFinished] = useState(!!videoData.signedId);
  const [duration, setDuration] = useState(0);
  const videoRef = useRef();

  const getVideoUrl = (id) =>
    `/rails/active_storage/blobs/redirect/${id}/ott-video-on-demand`;

  const [attachedVideo, setAttachedVideo] = useState(
    videoData.videoUrl
      ? videoData.videoUrl
      : videoData.signedId
      ? getVideoUrl(videoData.signedId)
      : ""
  );

  useEffect(() => {
    if (duration > 0) {
      onEnd();
    }
  }, [duration]);

  return (
    <DirectUploadProvider
      onSuccess={(signedId) => {
        setFinished(true);
        setVideoInput(signedId);
        setAttachedVideo(getVideoUrl(signedId));
      }}
      render={({ handleUpload, uploads, ready }) => (
        <>
          <div className={addClass ?? ""}>
            {!finished && uploads.length == 0 && (
              <>
                <div className="upload-zone">
                  {attachedVideo ? (
                    <div className="video-aspect">
                      <VideoPlayer
                        poster={poster(videoData.videoUid)}
                        sources={[
                          { src: videoUrl(videoData.videoUid) },
                          { src: attachedVideo },
                        ]}
                        controls
                      />
                    </div>
                  ) : (
                    <div className="click-to-upload">
                      <input
                        type="file"
                        disabled={!ready || !enoughStorage}
                        accept=".mov, .mp4"
                        onChange={(e) => handleUpload(e.currentTarget.files)}
                      />
                      {videoIcon}
                      <span>
                        {btnIcon}
                        Subir video
                      </span>
                      <p>
                        {uploadIcon}
                        Arrastra aqui el archivo o selecciona uno
                      </p>
                    </div>
                  )}
                </div>
              </>
            )}

            {finished && (
              <div className="upload-zone">
                <div
                  className="alert alert-puebla alert-dismissible fade show"
                  role="alert"
                >
                  ¡Subido exitosamente! Listo para guardar.
                </div>

                <video
                  src={attachedVideo}
                  onLoadedMetadata={({ target: { duration } }) =>
                    setDuration(Math.floor(duration))
                  }
                  controls
                  ref={videoRef}
                />

                <input type="hidden" value={videoInput} name={name} />
                <input
                  type="hidden"
                  value={duration}
                  name="ott_video[duration]"
                />
              </div>
            )}

            {uploads.map((upload) => {
              switch (upload.state) {
                case "waiting":
                  return (
                    <p key={upload.id} className="upload-info">
                      Preparando subida del vídeo...
                    </p>
                  );
                case "uploading":
                  return (
                    <>
                      {loadingIcon}
                      <div
                        className="upload-progress"
                        style={{
                          "--progress": `${Math.floor(upload.progress)}%`,
                        }}
                      />
                    </>
                  );
                case "error":
                  console.error(upload.error);
                  return (
                    <p key={upload.id} className="upload-info">
                      Ha ocurrido un error, inténtalo de nuevo
                    </p>
                  );
                case "finished":
                  return (
                    <p key={upload.id} className="upload-info">
                      Se subió {upload.file.name} correctamente
                    </p>
                  );
              }
            })}
          </div>
        </>
      )}
    />
  );
};

DirectVideoUpload.proptypes = {
  name: PropTypes.string.isRequired,
  videoData: PropTypes.objectOf(PropTypes.string),
  addClass: PropTypes.string,
};

DirectVideoUpload.defaultProps = {
  videoData: { signedId: "", videoUrl: "" },
  addClass: "",
  onStart: () => {},
  onEnd: () => {},
};

export default DirectVideoUpload;
