import React, { useEffect, useState } from "react";
import PreviewImageImprove from "../PreviewImageImprove";

const imgIcon = (
  <svg
    width="33"
    height="27"
    viewBox="0 0 33 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.2493 0.75H3.74935C2.33268 0.75 0.916016 2.16667 0.916016 3.58333V23.4167C0.916016 24.1681 1.21453 24.8888 1.74588 25.4201C2.27723 25.9515 2.9979 26.25 3.74935 26.25H29.2493C30.666 26.25 32.0827 24.8333 32.0827 23.4167V3.58333C32.0827 2.16667 30.666 0.75 29.2493 0.75ZM6.58268 20.5833L11.541 14.2083L15.0827 18.4583L20.041 12.0833L26.416 20.5833H6.58268Z"
      fill="#FFD1D2"
    />
  </svg>
);

const uploadIcon = (
  <svg
    width="18"
    height="13"
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.667969 7.5H2.33464V11.25H15.668V7.5H17.3346V11.25C17.3346 12.0825 16.593 12.75 15.668 12.75H2.33464C1.41797 12.75 0.667969 12.0825 0.667969 11.25V7.5ZM9.0013 0L4.38464 4.095L5.56797 5.16L8.16797 2.8125V9.75H9.83463V2.8125L12.443 5.16L13.6263 4.0875L9.0013 0Z"
      fill="#575757"
    />
  </svg>
);

const btnIcon = (
  <svg
    width="15"
    height="17"
    viewBox="0 0 15 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 13V7H0.5L7.5 0L14.5 7H10.5V13H4.5ZM0.5 17V15H14.5V17H0.5Z"
      fill="#E31519"
    />
  </svg>
);

const ThumbnailSwitch = ({ uid, imgFile, currentTime, maxTime }) => {
  const [useTimestamp, setUserTimestamp] = useState(true);
  const [timestamp, setTimestamp] = useState(currentTime);
  const [displayTime, setDisplayTime] = useState('00:00');

  useEffect(() => {
    const length = timestamp > 3600 ? 11 : 14
    const formatted = new Date(timestamp * 1000).toISOString().slice(length, 19);

    setDisplayTime(formatted);
    
  }, [timestamp])

  return (
    <div className="ott-video__thumb-area">
      {!useTimestamp && (
        <>
          <div className="ott-video__thumb-area__content">
            {imgIcon}
            <span>
              {btnIcon}
              Subir imagen
            </span>
            <p>
              {uploadIcon}
              Arrastra aqui el archivo o selecciona uno
            </p>
          </div>

          <PreviewImageImprove
            initialImage={imgFile}
            inputData={{ model: "ott_video", name: "thumbnail" }}
            aditionalClassName="ott-video__thumbnail"
          />
        </>
      )}

      {useTimestamp && (
        <>
          <img
            src={`https://customer-agqk7n0hz61pv4iy.cloudflarestream.com/${uid}/thumbnails/thumbnail.jpg?time=${timestamp}s`}
            className="ott-video__thumb-area__image"
          />
          <div className="timestamp-slider">
            <input
              className="input-range"
              type="range"
              min="0"
              max={maxTime}
              name="ott_video[thumbnail_timestamp]"
              value={timestamp ?? 0}
              onChange={({ target }) => setTimestamp(target.value)}
            />
            <span className="number-display">
              {displayTime}
            </span>
          </div>
        </>
      )}

      <button
        className="switch-thumbnail-btn"
        type="button"
        onClick={() => setUserTimestamp(!useTimestamp)}
      >
        {useTimestamp ? "Usar imagen" : "Usar tiempo"}
      </button>
    </div>
  );
};

export default ThumbnailSwitch;
