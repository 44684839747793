import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import DirectVideoUpload from "./DirectVideoUpload";
import ThumbnailSwitch from "./ThumbnailSwitch";
import StreamPlayer from "./StreamPlayer";
import CopyToClipboard from "../CopyToClipboard";

const OttVideosForm = ({ currentValues, tags }) => {
  const [currentTags, setCurrentTags] = useState(
    currentValues.tags.map((tag) => ({
      value: tag.id,
      label: tag.name,
      profile: tag.taggable_id,
    })) ?? []
  );

  const submitBtn = useRef();
  const titleRef = useRef();
  const descriptionRef = useRef();

  const {
    signedId,
    videoUrl,
    videoUid,
    thumbnail,
    timestamp,
    duration,
    title,
    description,
    publishDate,
    slug,
    streamKey,
    publicUrl,
    enoughStorage
  } = currentValues;

  const [allowSave, setAllowSave] = useState(
    !!(signedId || videoUrl || videoUid || streamKey)
  );

  useEffect(() => {
    if (!!(signedId || videoUrl || videoUid || streamKey)) return;

    if (
      allowSave &&
      [titleRef, descriptionRef].every((i) => i.current.value.trim() !== "")
    ) {
      submitBtn.current.click();
    }
  }, [allowSave]);

  return (
    <div className="row">
      <div className="col-12 d-flex justify-content-end">
        <div className="col-2 text-right">
          {(videoUid || streamKey) && (
            <select
              className="form-select"
              defaultValue={currentValues.status}
              name="ott_video[status]"
            >
              <option value="" selected disabled>
                Elegir status
              </option>
              <option value="Pending">Pendiente</option>
              <option value="Ready to be published">Listo para publicar</option>
              <option value="Homepage">Homepage</option>
            </select>
          )}
        </div>
      </div>

      <div className="col-6 mb-3 video-aspect">
        {!streamKey && (
          <>
            <label name="ott_video[video]" className="form-label">
              {slug ? 'Vista previa de video' : 'Subir video'}
            </label>
            <DirectVideoUpload
              enoughStorage={enoughStorage}
              name="ott_video[video_signed_id]"
              videoData={{ signedId, videoUrl, videoUid }}
              addClass={`ott-video__video ${videoUid ? "" : "video-aspect"}`}
              onEnd={() => setAllowSave(true)}
            />
          </>
        )}
        {streamKey && (
          <>
            <p className="form-label">Transmisión</p>
            <StreamPlayer controls src={streamKey} />
          </>
        )}
      </div>

      {(videoUid || streamKey) && (
        <div className="col-6 mb-3 mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <label name="ott_video[thumbnail]" className="form-label">
              Miniatura del video
            </label>
          </div>

          <ThumbnailSwitch
            uid={videoUid ?? streamKey}
            imgFile={thumbnail}
            currentTime={timestamp}
            maxTime={duration}
          />
        </div>
      )}

      <div className="col-6">
        <div className="mb-3">
          <label name="ott_video[title]" className="form-label">
            Titulo del Video
          </label>
          <input
            defaultValue={title}
            ref={titleRef}
            type="text"
            name="ott_video[title]"
            className="form-control"
            placeholder="Este es el titulo del video"
          />
          {signedId && !title && (
            <span className="error">El título es obligatorio</span>
          )}
        </div>

        <div className="mb-3">
          <label name="ott_video[description]" className="form-label">
            Descripción
          </label>
          <textarea
            defaultValue={description}
            ref={descriptionRef}
            name="ott_video[description]"
            rows="3"
            className="form-control"
            placeholder="Esta es la descripción del video"
          />
          {signedId && !description && (
            <span className="error">La descripción es obligatoria</span>
          )}
        </div>
      </div>

      <div className={videoUid || streamKey ? "col-6" : "col-12 row"}>
        <div className="row col-12">
          <div className="col-6 mb-3">
            <label name="ott_video[publish_date]" className="form-label">
              Fecha de Publicación
            </label>
            <input
              defaultValue={publishDate}
              type="datetime-local"
              name="ott_video[publish_date]"
              className="form-control"
            />
          </div>

          {slug && (
            <div className="col-6 mb-3">
              <label name="ott_video[slug]" className="form-label">
                Slug
              </label>
              <div className="d-flex">
                <input
                  defaultValue={slug}
                  name="ott_video[slug]"
                  className="form-control"
                />
                <CopyToClipboard copy={publicUrl} classname="clean-btn" />
              </div>
            </div>
          )}

          <div className={videoUid || streamKey ? "col-12" : "col-6"}>
            <label name="tags" className="form-label">
              Tags
            </label>

            {currentTags.map((t) => (
              <input
                type="hidden"
                value={t.value}
                name="ott_video[ott_tag_ids][]"
              />
            ))}

            <Select
              isMulti
              name="tags"
              placeholder="Buscar o asignar tags"
              options={tags.map((tag) => ({
                value: tag.id,
                label: tag.name,
                profile: tag.taggable_id,
              }))}
              styles={{
                option: (styles, { data, isFocused }) => ({
                  ...styles,
                  backgroundColor: isFocused
                    ? data.profile
                      ? "#FFE69C"
                      : "#E9ECEF"
                    : "white",
                }),
                multiValue: (styles, { data }) => ({
                  ...styles,
                  backgroundColor: data.profile ? "#FFE69C" : "#E9ECEF",
                }),
                menu: (styles) => ({
                  ...styles,
                  zIndex: 10,
                }),
              }}
              className="basic-multi-select"
              value={currentTags}
              onChange={(e) => setCurrentTags(e)}
            />
          </div>
        </div>
      </div>
      <button className="btn btn-publish" disabled={!allowSave} ref={submitBtn}>
        Guardar
      </button>
    </div>
  );
};

export default OttVideosForm;
