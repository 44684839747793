import React, { useState } from "react";

const SocialMediaInput = ({ social, label, placeholder, current }) => {
  const [urlValue, setUrlValue] = useState(current ? current.url : "");

  return (
    <>
      <label className="form-label">{label}</label>
      <input
        name={`connections[${social}][title]`}
        value={social}
        type="hidden"
        className="form-control"
      />
      <input
        name={`connections[${social}][url]`}
        value={urlValue}
        onChange={({ target }) => setUrlValue(target.value)}
        className="form-control"
        placeholder={placeholder}
      />
    </>
  );
};

export default SocialMediaInput;
